export interface ITipoLogradouro {
  id?: number;
  codigo: string;
  descricao: string;
}

export default class TipoLogradouro implements ITipoLogradouro {
  public id?: number;

  public codigo: string;

  public descricao: string;

  constructor() {
    this.codigo = '';
    this.descricao = '';
  }
}
