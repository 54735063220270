export interface IEstadoMunicipioIBGE {
  id?: number;
  codigoEstado: string;
  descricaoEstado: string;
  ufEstado: string;
  codigoMunicipio: string;
  descricaoMunicipio: string;
}

export default class EstadoMunicipioIBGE implements IEstadoMunicipioIBGE {
  public id?: number;

  public codigoEstado: string;

  public descricaoEstado: string;

  public ufEstado: string;

  public codigoMunicipio: string;

  public descricaoMunicipio: string;

  constructor() {
    this.codigoEstado = '';
    this.descricaoEstado = '';
    this.ufEstado = '';
    this.codigoMunicipio = '';
    this.descricaoMunicipio = '';
  }
}
