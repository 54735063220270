import EstadoMunicipioIBGE, { IEstadoMunicipioIBGE } from '../EstadoMunicipioIBGE/EstadoMunicipioIBGE';
import Pais, { IPais } from '../Pais/Pais';
import TipoLogradouro, { ITipoLogradouro } from '../TipoLogradouro/TipoLogradouro';

export interface IEndereco {
  cep: string;
  rua?: string;
  numero?: string;
  complemento?: string;
  bairro?: string;
  cidade?: string;
  regiao?: string;
  estado: number | null;
  uf: number | string;
  paisId?: number | null;
  pais?: IPais;
  codigoPostalPais?: string | null;
  estadosMunicipiosIBGEId?: number | string;
  estadosMunicipiosIBGE ?: IEstadoMunicipioIBGE;
  tipoLogradouroId?: number | null;
  tipoLogradouro?: ITipoLogradouro;
}

export default class Endereco implements IEndereco {
  public cep: string;

  public rua?: string;

  public numero?: string;

  public complemento?: string;

  public bairro?: string;

  public cidade?: string;

  public regiao?: string;

  public estado: number | null;

  public uf: number | string;

  public paisId?: number | null;

  public pais?: IPais;

  public codigoPostalPais?: string | null;

  public estadosMunicipiosIBGEId?: number | string;

  public estadosMunicipiosIBGE?: IEstadoMunicipioIBGE;

  public tipoLogradouroId?: number | null;

  public tipoLogradouro?: ITipoLogradouro;

  constructor() {
    this.cep = '';
    this.rua = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.cidade = '';
    this.regiao = '';
    this.estado = null;
    this.uf = '';
    this.paisId = null;
    this.pais = new Pais();
    this.codigoPostalPais = '';
    this.estadosMunicipiosIBGEId = '';
    this.estadosMunicipiosIBGE = new EstadoMunicipioIBGE();
    this.tipoLogradouroId = null;
    this.tipoLogradouro = new TipoLogradouro();
  }
}
